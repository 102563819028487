import type { AccountInfo, IPublicClientApplication } from '@azure/msal-browser-1p';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { setIsAuthenticated } from '../isAnonymousSession';
import { setOwaAppId } from 'owa-config/lib/getOwaAppId';
import { logStartUsage } from 'owa-analytics-start';
import type { CustomDataMap } from 'owa-analytics-types';
import { getLoginHint } from './getLoginHint';
import { setItem } from 'owa-local-storage';
import { setIdleSessionCookieIfEnabled } from './setIdleSessionCookieIfEnabled';

const mailboxInfoIndexerValue_to_homeAccountId = new Map<string, string>();

export function getAccountFromMsal(
    msalInstance: IPublicClientApplication,
    mailboxInfo: MailboxInfo,
    isNaa: boolean = false
): AccountInfo | null {
    let account: AccountInfo | null = null;
    const diagnosticData: CustomDataMap = { isNaa };

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    let homeAccountId = mailboxInfoIndexerValue_to_homeAccountId.get(key);
    diagnosticData.hasMailboxInfoIndexerValue = !!key;
    diagnosticData.homeAccountIdFoundInCache = !!homeAccountId;

    const sessionData = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    diagnosticData.hasSessionSettings = !!sessionData;

    if (!homeAccountId && sessionData) {
        if (sessionData.ExternalDirectoryUserGuid && sessionData.ExternalDirectoryTenantGuid) {
            homeAccountId = `${sessionData.ExternalDirectoryUserGuid}.${sessionData.ExternalDirectoryTenantGuid}`;
        }

        diagnosticData.hasExternalDirectoryUserGuid = !!sessionData.ExternalDirectoryUserGuid;
        diagnosticData.hasExternalDirectoryTenantGuid = !!sessionData.ExternalDirectoryTenantGuid;
    }

    if (homeAccountId) {
        account = msalInstance.getAccount({ homeAccountId });
        diagnosticData.getAccountByHomeAccountIdResult = account ? 'Success' : 'Failure';
    }

    if (!account) {
        const loginHint = getLoginHint(undefined /*msalAccount*/, mailboxInfo);
        diagnosticData.hasLoginHint = !!loginHint;

        if (loginHint) {
            account = msalInstance.getAccount({ loginHint });

            if (account && !account.idToken) {
                // [MSAL bug workaround] msal-browser ignores the loginHint filter if the account in cache does not
                // have an idToken. In that case, it returns the first account in cache. So, if the returned account
                // doesn't have an idToken, we cannot consider this a reliable account match.
                account = null;
            }

            diagnosticData.getAccountByLoginHintResult = account ? 'Success' : 'Failure';
        }
    }

    if (key && account) {
        mailboxInfoIndexerValue_to_homeAccountId.set(key, account.homeAccountId);
    }

    if (!account) {
        logStartUsage('Msal-GetAccountFromMsal-Failure', diagnosticData);
    }

    return account;
}

export function getAccountFromMsalByUsername(
    msalInstance: IPublicClientApplication,
    username: string
): AccountInfo | null {
    return msalInstance.getAccount({ username });
}

export function getAllAccountFromMsal(msalInstance: IPublicClientApplication): AccountInfo[] {
    return msalInstance.getAllAccounts();
}

export function getActiveAccountFromMsal(
    msalInstance: IPublicClientApplication
): AccountInfo | null {
    return msalInstance.getActiveAccount();
}

export function setActiveAccountFromMsal(
    msalInstance: IPublicClientApplication,
    account: AccountInfo
): void {
    msalInstance.setActiveAccount(account);
}

export function setActiveAccountRelatedProperties(
    msalInstance: IPublicClientApplication,
    account: AccountInfo
): void {
    // store the app id from msal config in local token storage. This will be used to sync the app id between the client and server.
    setOwaAppId(msalInstance.getConfiguration().auth.clientId);
    setActiveAccountFromMsal(msalInstance, account);
    setItem(self, 'login_hint', account.idTokenClaims?.login_hint ?? account.username); // store login_hint claim value or upn in persistent storage for SSO.

    // most apps that rely on Anonymous sessions like FindTime, Bookings & BookWithMe
    // need this to determine whether a session is authenticated or not.
    setIsAuthenticated();

    setIdleSessionCookieIfEnabled(account);
}

export function setMailboxInfoToAccountMapping(
    mailboxInfo: MailboxInfo,
    account: AccountInfo
): void {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    if (key) {
        mailboxInfoIndexerValue_to_homeAccountId.set(key, account.homeAccountId);
    }
}
